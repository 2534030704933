import FileInterface from './interfaces/FileInterface';
import { URL_API_FILES } from '../constants/URL_API';

class File {
    readonly id ? : number
    filepath ? : string
    format ? : "png" | "jpeg" | "jpg"

    public get url_img() : string{
        return URL_API_FILES + ( this.filepath ?? "")
    }

    constructor(file ? : FileInterface) {
        this.id = file?.id
        this.filepath = file?.filepath
        this.format = file?.format
    }
}

export default File
