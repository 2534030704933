import * as React from 'react';
import Form, { FormCustomInterface } from '../../../components/Form/Form';
import Page from '../../../components/Page/Page';
import Table from '../../../components/Table/Table';
import useAPI from '../../../hooks/useAPI';
import Customer from '../../../objects/Customer';
import PageContext from '../../../hooks/contexts/PageContext';
import "./Customers.css"
import CustomFieldInterface from '../../../objects/interfaces/CustomFieldInterface';
import Order from '../../../objects/Order';
import NfcTag from '../../../objects/NfcTag';
import NfcTagInterface from '../../../objects/interfaces/NfcTagInterface';
import Field from '../../../components/Field/Field';
import useData from '../../../hooks/useData';
export interface CustomersProps {
    history : any
}

export interface ModalCustomerProps {
    history ? : any
    show : boolean
    customer ? : Customer
    onValidate ? : (obj ? : any) => any
    onClose : () => any
}
const Customers: React.FunctionComponent<CustomersProps> = ({history}) => {

    const [customers, setCustomers] = React.useState<Array<Customer>>([])
    const [customersData, setCustomersData] = useData("customers")
    const [multipleSelected, setMultipleSelected] = React.useState<Array<number>>([])

    const [nbPoint, setNbPoint] = React.useState<number>(0);

    const [customerSelected, setCustomerSelected] = React.useState<Customer>()

    const [getCustomers, responseGetCustomers] = useAPI("CUSTOMERS", "GET")
    React.useEffect(() => {
        const {onSuccess, data} = responseGetCustomers
        if(onSuccess) {
            setCustomers(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseGetCustomers])

    const [addPointsToCustomers, responseAddPointsToCustomers] = useAPI("ADD_POINTS_TO_CUSTOMERS", "PUT")
    React.useEffect(() => {
        const {onSuccess, data} = responseAddPointsToCustomers
        if(onSuccess) {
            let newCustomers : Array<Customer> = customers.reduce((acc: Array<Customer>, curr) => {
                if(multipleSelected.includes(curr.id??0)) {
                    curr.nbPoint = (curr?.nbPoint ?? 0) + nbPoint
                }
                acc.push(curr)
                return acc
            }, [])
            setMultipleSelected([])
            setCustomers(newCustomers)
            setCustomersData(newCustomers)
            setNbPoint(0)
        }
    }, [responseAddPointsToCustomers]);

    React.useEffect(() => {
        getCustomers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onFailure = () => {

    }

    const onNewSelected = (id : number) => {
        let newMultipleSelected = [...multipleSelected]
        if(newMultipleSelected.includes(id)) {
            newMultipleSelected = multipleSelected.filter(a => a !== id)
            setMultipleSelected(newMultipleSelected)
        } else {
            newMultipleSelected.push(id)
            setMultipleSelected(newMultipleSelected)
        }
    }

    const onChangePoint = (nb : string) => {
        let nbPoint = 0
        if(nb !== "") nbPoint = parseInt(nb)
        setNbPoint(nbPoint)
    }

    const onValidateAddPoint = () => {
        addPointsToCustomers({body : {
            idCustomers : multipleSelected,
            nbPoint
        }})
    }

    const onCustomerEdit = (newCustomer ? : Customer) => {
        if(!newCustomer) throw new Error("A")
        let newCustomers : Array<Customer> = customers.reduce((acc: Array<Customer>, curr) => {
            if(newCustomer.id === curr.id) {
                if(!curr.nbPoint) curr.nbPoint = 0
                let addPoint = newCustomer?.addPoint ?? 0
                if(typeof addPoint ==="string" ) addPoint = parseInt(addPoint)
                curr.nbPoint += addPoint
            }
            acc.push(curr)
            return acc
        }, [])
        setMultipleSelected([])
        setCustomers(newCustomers)
        setCustomersData(newCustomers)
        setCustomerSelected(undefined)
    }
    return ( 
        <Page history={history} name="Clients">
                <PageContext.Provider value={{list : customers, setList : setCustomers, onFailure}}>
            <div className="container-customers">
                {multipleSelected.length > 0 && <>
                <Field wording="Nombre de point à ajouter" value={nbPoint.toString()} onChange={onChangePoint} />
                <div onClick={onValidateAddPoint} className="btn bg-black">
                    Ajouter
                </div>
                </>}
                    <Table customCsv={Customer.EXPORT_CSV}  isExportable={true} filters={Customer.FILTERS_FOR_CUSTOMERS} data={customers} columns={Customer.COLUMNS} onClickItem={(customer) => setCustomerSelected(customer) } multipleSelected={multipleSelected} isMultipleSelect={true} onSelectedMultiple={onNewSelected}/>
            </div>
            <ModalCustomer history={history} onValidate={onCustomerEdit} onClose={() => setCustomerSelected(undefined)} customer={customerSelected}  show={(customerSelected ? true : false)}/>
            </PageContext.Provider>
        </Page>
     );
}

const ModalCustomer : React.FunctionComponent<ModalCustomerProps> = ({show = false, customer, onClose, history, onValidate}) => {

    const [nfcTags, setNfcTags] = React.useState<Array<NfcTag>>([]);

    const [getNfcTags, responseGetNfcTags] = useAPI("NFC_TAGS_BY_CUSTOMERS", "GET")
    React.useEffect(() => {
        const {onSuccess, data} = responseGetNfcTags
        if(onSuccess) {
            let nfcTags :  Array<NfcTag> = data.reduce((acc : Array<NfcTag>, curr : NfcTagInterface) => {
                acc.push(new NfcTag(curr))
                return acc
            }, [])
            setNfcTags(nfcTags)
        }
    }, [responseGetNfcTags]);
    React.useEffect(() => {
        if(customer?.id)
        getNfcTags({id : customer.id})
    }, [customer]);

    const customFields : Array<CustomFieldInterface> = [
        {
            name : "historyOrders",
            renderItem : (orders : Array<Order>) => <OrderTable history={history} orders={orders} />
        }, {
            name : "listProducts",
            renderItem : () => <ListProducts history={history} nfcTags={nfcTags} />
        }
    ]

    return (
            <div className={show ? "modal-customer" : "container-modal-hide"}>
                <div className="customer-container">
                    <Form
                        item={customer ?? {} }
                        type="PUT"
                        request="CUSTOMERS"
                        onValidate={onValidate}
                        form={Customer.FORM_ADD}
                        object={Customer}
                        onClose={onClose}
                        getItem={true}
                        btnDelete={false}
                        customPage={(form) => <ModelFormCustomer form={form} />}
                        customFields={customFields}
                    />
                </div> 
            </div>
    )
}


interface ModelFormCustomerProps {
    form : Array<FormCustomInterface>
}

const ModelFormCustomer : React.FunctionComponent<ModelFormCustomerProps> = ({form}) => {
    return (
        <div className="form-customer">
            <div className="customer-grid-1 bg-white">
                {form.map(field =>
                    field.position === "customer-grid-1" &&
                        <div style={{width:"25%", float:"left"}}>
                        {field.component}
                        </div>
                )}
            </div>
            <div className="customer-grid-2 bg-white">
                    <>
                        <label style={{fontFamily:"bold"}} className="customer-grid-2-title">Contact</label>

                        <div className="customer-grid-2-1">
                            {form.map(field =>
                                field.position === "customer-grid-2-1" &&
                                    <div >
                                    {field.component}
                                    </div>
                            )}
                        </div>
                        <div className="customer-grid-2-2">
                            {form.map(field =>
                                field.position === "customer-grid-2-2" &&
                                    <div >
                                    {field.component}
                                    </div>
                            )}
                        </div>
                    </>
            </div>
            <div className="customer-grid-3 bg-white">
            <label style={{fontFamily:"bold"}} className="customer-grid-3-title">Statistiques</label>
                <div className="customer-grid-3-1">
                {form.map(field =>
                    field.position === "customer-grid-3-1" &&
                        <div style={{width:"33%", float:"left"}}>
                        {field.component}
                        </div>
                )}
                </div>
                </div>
                <div className="customer-grid-4 bg-white">
                    <label style={{fontFamily:"bold"}} className="customer-grid-3-title">Historique des commandes</label>
                    {form.map(field =>
                        field.position === "customer-grid-4-1" &&
                            field.component
                    )}
                    </div>
                    <div className="customer-grid-5 bg-white">
                        {form.map(field =>
                            field.position === "customer-grid-5" &&
                                <div>
                                {field.component}
                                </div>
                        )}
                    </div>

            <div className="customer-grid-8 bg-white">
            {form.map(field => 
                field.position === "buttons" && field.component
            )}
        </div>
        <div className="customer-grid-9 bg-white">
        <label style={{fontFamily:"bold"}} className="customer-grid-3-title">Produits</label>
        {form.map(field =>
                    field.position === "customer-grid-9-1" &&
                        field.component
                )}
        </div>
        </div>
    )
}


interface OrdersTableProps {
    orders : Array<Order>
    history ? : any
}

const OrderTable  : React.FunctionComponent<OrdersTableProps> = ({orders, history}) => {
    console.log(orders)
    return (
        <div className="table-orders">
            {orders?.map(order => 
                <div className="order" onClick={() => history.push(`/orders/${order.id}`)}>
                    {order.dateOrder}  {order.custom_id} = {order.totalPrice}
                </div>
            )}
        </div>
    )
}

interface ListProductsProps {
    nfcTags : Array<NfcTag>
    history ? : any
}

const ListProducts  : React.FunctionComponent<ListProductsProps> = ({nfcTags, history}) => {
    console.log(nfcTags)
    return (
        <div className="table-orders">
            {nfcTags?.map(nfcTag => 
                <div className="order" onClick={() => history.push(`/products/${nfcTag.id}`)}>
                    {nfcTag.productName} 
                </div>
            )}
        </div>
    )
}

export default Customers;
