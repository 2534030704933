import * as React from 'react';
import { useState } from 'react';
import ListItems from '../../../components/ListItems/ListItems';
import Page from '../../../components/Page/Page';
import "./Products.css"
import Product from '../../../objects/Product';
import useAPI from '../../../hooks/useAPI';
import { useEffect } from 'react';
import PageContext from '../../../hooks/contexts/PageContext';
import Form, { FormCustomInterface, IModalDelete } from '../../../components/Form/Form';
import Category from '../../../objects/Category';
import Collection from '../../../objects/Collection';
import SelectTargetPerson from '../../../components/SelectTargetPerson/SelectTargetPerson';

export interface ProductsProps {
    history: any
    from?: "category" | "collection" | ""
    match: any
}


export interface ModalProductProps {
    show: boolean
    product?: "new" | Product
    onClose: () => any
    from?: "category" | "collection" | ""
    collection?: Collection
    category?: Category
}


export interface ItemProductInterface {
    source: string
    title: string
    color: string
    reference: string
    price: number
    points: number
    collection: string
    onClick: () => any
}

const Products: React.FunctionComponent<ProductsProps> = ({ history, from = "", match }) => {

    const { id = null } = match.params


    const [title, setTitle] = useState<string>("Produits")


    const [products, setProducts] = useState<Array<Product>>([])
    const [filterProducts, setFilterProducts] = useState<Array<Product>>([])

    const [category, setCategory] = useState<Category>()
    const [collection, setCollection] = useState<Collection>()

    const [selectedProduct, setSelectedProduct] = useState<Product | "new">()

    const [getCollection, responseGetCollection] = useAPI("COLLECTIONS", "GET")
    useEffect(() => {
        const { onSuccess, data } = responseGetCollection
        if (onSuccess) {
            setCollection(data)
            setTitle(data.name.fr)

        }
    }, [responseGetCollection])

    const [getCategory, responseGetCategory] = useAPI("CATEGORIES", "GET")
    useEffect(() => {
        const { onSuccess, data } = responseGetCategory
        if (onSuccess) {
            setCategory(data)
            setTitle(data.name.fr)
        }

    }, [responseGetCategory])

    const [getProducts, responseGetProducts] = useAPI("PRODUCTS", "GET")
    useEffect(() => {
        const { onSuccess, data } = responseGetProducts
        if (onSuccess) setProducts(data)
    }, [responseGetProducts])


    React.useEffect(() => {
        if (from !== "") {
            if (from === "category") {
                getProducts({ params: { category: id } })
                getCategory({ id })
            }
            if (from === "collection") {
                getProducts({ params: { collection: id } })
                getCollection({ id })
            }
        } else if (from === "") getProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const ItemProduct: React.FunctionComponent<ItemProductInterface> = ({ source, title, color, reference, price, points, collection, onClick }) => {
        return (
            <div onClick={onClick} className="item-product bg-white" style={{ width: "90%", margin: "auto", marginTop: "20px", display: "flex", flexDirection: "row", padding: "3%", justifyContent: "space-between", cursor: "pointer" }}>
                <img alt="" src={source} style={{ width: "5%" }} />
                <div className="" style={{ width: "90%", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <b>{title}</b>
                    <label style={{ cursor: "pointer" }}><b>reference : </b>{reference}</label>
                    <label style={{ cursor: "pointer" }}><b>couleur : </b>{color}</label>
                    <label style={{ cursor: "pointer" }}><b>prix : </b>{price} €</label>
                    <label style={{ cursor: "pointer" }}><b>points : </b>{points} points</label>
                    <label style={{ cursor: "pointer" }}><b>collection : </b>{collection}</label>
                </div>
            </div>
        )
    }


    const onFailure = (item: Product) => {
        setSelectedProduct(item ?? "new")
    }

    return (
        <Page history={history} name={title}>
            <div className="container-products">
                <PageContext.Provider value={{ list: products, setList: setProducts, onFailure }}>
                    <ListItems onClickAddBtn={() => setSelectedProduct("new")} filters={(from === "collection") ? Product.FILTERS_FOR_COLLECTION : Product.FILTERS_FOR_CATEGORY} data={products} item={(item) =>
                        <ItemProduct
                            key={item.id}
                            source={item.principal_visual.url_img}
                            title={item.name.fr}
                            reference={item.reference}
                            price={item.price}
                            points={item.point}
                            color={item.color}
                            collection={item.collection.name.fr}
                            onClick={() => setSelectedProduct(item)}
                        />}
                    />
                    <ModalProduct show={selectedProduct ? true : false} product={selectedProduct} onClose={() => setSelectedProduct(undefined)} from={from} collection={collection} category={category} />
                </PageContext.Provider>
            </div>
        </Page>
    );
}

const ModalProduct: React.FunctionComponent<ModalProductProps> = ({ show = false, product, onClose, from, collection, category }) => {
    let data = new Product(undefined)
    if (from === "category") data.category = category
    if (from === "collection") data.collection = collection

    return (
        <div className={show ? "modal-product" : "container-modal-hide"}>
            {product === "new" ? <div className="product-container">
                <Form
                    type="POST"
                    request="PRODUCTS"
                    form={Product.FORM_ADD}
                    object={Product}
                    onClose={onClose}
                    customPage={(form) => <ModelFormProduct form={form} />}
                    data={data}
                />
            </div> : <div className="product-container">
                <Form
                    item={product ?? {}}
                    type="PUT"
                    request="PRODUCTS"
                    form={Product.FORM_ADD}
                    object={Product}
                    getItem={true}
                    onClose={onClose}
                    showPopUpDelete={true}
                    customPage={(form, modalDelete) => <ModelFormProduct form={form} modalDelete={modalDelete} />}
                />
            </div>}

        </div>
    )
}

interface ModelFormProductProps {
    form: Array<FormCustomInterface>
    modalDelete ? : IModalDelete
}
const ModelFormProduct: React.FunctionComponent<ModelFormProductProps> = ({ form , modalDelete }) => {

    return (
        <div className="form-product">
            <div className="product-grid-1 bg-white">
                {form.map((field, idx) =>
                    field.position === "product-grid-1" &&
                    <div key={idx.toString()} style={{ width: "20%", float: "left" }}>
                        {field.component}
                    </div>
                )}
            </div>
            <div className="product-grid-2 bg-white">
                {form.map((field, idx) =>
                    field.position === "product-grid-2" &&
                    <div key={idx.toString()} style={{ margin: "auto" }}>
                        {field.component}
                    </div>
                )}
            </div>
            <div className="product-grid-3 bg-white">
                {form.map((field, idx) =>
                    field.position === "product-grid-3" &&
                    <div key={idx.toString()} style={{ margin: "auto" }}>
                        {field.component}
                    </div>
                )}
            </div>
            <div className="product-grid-4 bg-white">
                {form.map((field, idx) =>
                    field.position === "product-grid-4" &&
                    <div key={idx.toString()} style={{ margin: "auto" }}>
                        {field.component}
                    </div>
                )}
            </div>

            <div className="product-grid-5 bg-white">
                {form.map((field, idx) =>
                    field.position === "product-grid-5" &&
                    <div key={idx.toString()} style={{ margin: "auto" }}>
                        {field.component}
                    </div>
                )}
            </div>
            <div className="product-grid-6 bg-white">
                {form.map((field, idx) =>
                    field.position === "product-grid-6" &&
                    <div key={idx.toString()} style={{ margin: "auto" }}>
                        {field.component}
                    </div>
                )}
            </div>
            <div className="product-grid-7 bg-white">
                {form.map((field, idx) =>
                    field.position === "product-grid-7" &&
                    <div key={idx.toString()} style={{ margin: "auto" }}>
                        {field.component}
                    </div>
                )}
            </div>
            <div className="product-grid-9 bg-white">
            {form.map((field, idx) =>
                    field.position === "product-grid-9" &&
                    <div key={idx.toString()} style={{ margin: "auto" }}>
                        {field.component}
                    </div>
                )}
            </div>
            <div className="product-grid-8 bg-white">
                {form.map((field, idx) =>
                    <div key={idx.toString()}>
                        {field.position === "buttons" && field.component}
                    </div>
                )}
            </div>

            {modalDelete?.openModal && <div className="modal-product-delete-background">
                    <div className="modal-product-delete">
                        <h4>Suppression</h4>
                        Etes vous sur de vouloir supprimer
                        <div className="actions">
                            <div onClick={() => modalDelete?.onClickDelete && modalDelete.onClickDelete()} className="btn bg-success">Oui</div>
                            <div onClick={() => modalDelete?.onCloseModal && modalDelete.onCloseModal()} className="btn bg-danger">Non</div>
                        </div>
                    </div>
            </div>}
        </div>
    )
}


export default Products;
