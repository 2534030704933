import CustomerInterface from './interfaces/CustomerInterface';
import User from './User';
import ColumnInterface from './interfaces/ColumnInterface';
import FormInterface from './interfaces/FormInterface';
import Order from './Order';
import OrderInterface from './interfaces/OrderInterface';
import hundredth from '../utils/hundredth';
import dateToString from '../utils/dateToString';
import FilterInterface from './interfaces/FilterInterface';

class Customer {
    readonly id : number | undefined
    readonly customId : string | undefined
    user ? : User
    phone ? : string
    orders ? : Array<Order>
    username : string
    country ? : string
    gender ? : "H" | "F"
    firstName ?: string
    lastName ? : string
    nbPoint  ? : number
    newPoint ? : number | string
    total_expenditure ? : number
    address ? : string
    zipCode ? : string
    city ? : string
    date_first_order ? : Date
    date_last_order ? : Date
    email ? : string

    constructor(customer ? : CustomerInterface){
        this.id = customer?.id ?? 0
        this.customId = customer?.customId
        if(customer?.user) this.user = new User(customer.user)
        this.country = customer?.country
        this.gender = customer?.gender
        this.username = customer?.username ?? ""
        this.firstName = customer?.firstName
        this.lastName = customer?.lastName
        this.address = customer?.address
        this.zipCode = customer?.zipCode
        this.city = customer?.city
        this.nbPoint = customer?.nbPoint ?? 0
        this.total_expenditure = customer?.total_expenditure ?? 0
         let orders : Array<Order> = []
        customer?.orders?.map((order : OrderInterface) => {
            orders.push(new Order(order))
        })
        this.orders =orders
        this.date_first_order = customer?.date_first_order ? new Date(customer.date_first_order) : undefined
        this.date_last_order = customer?.date_last_order ? new Date(customer.date_last_order) : undefined
        this.phone = customer?.phone ?? ""
        this.email = customer?.email ?? "-"

    }

    public get dateFirstOrder() {
        return this.date_first_order ? dateToString(this.date_first_order) : "-"
    }

    public get dateLastOrder() {
        return this.date_last_order ? dateToString(this.date_last_order) : "-"
    }

    public get custom_id() {
        return this.customId
    }

    public get last_name() {
        return this.lastName ?? "-"
    }

    public get first_name() {
        return this.firstName ?? "-"
    }

    public get totalExpend() {
        return `${hundredth(this.total_expenditure ?? 0)} €`
    }

    public get manOrWoman() {
        return (this.gender === "H") ? "Homme" : (this.gender === "F") ? "Femme" :"-"
    }

    public get addressCustomer() {
        return this.address ?? "-"
    }

    public get cityCustomer() {
        return this.city ?? "-"
    }

    public get zipCodeCustomer() {
        return this.zipCode ?? "-"
    }

    public get nbTotalOrders() {
        return this.orders?.length ?? 0
    }

    public get averagePrice() {
        if((!this.total_expenditure || this.total_expenditure === 0) || this.orders?.length === 0) return '0 €'
        return`${hundredth(( this.total_expenditure ?? 1) / (this.orders?.length ?? 1)) ?? 0} €`
     }

     public get points() {
         let pts : number = this.nbPoint ?? 0

         let addPtsString : string = this.newPoint?.toString() ?? "0"
         if(addPtsString === "") addPtsString = "0"
         let addPts = parseInt(addPtsString)
         console.log(typeof pts)
         console.log(typeof addPts)
         return pts + addPts
     }

     public get addPoint() {
         if(typeof this.newPoint === "string") parseInt(this.newPoint?? "0")
        return this.newPoint ?? 0
     }

     public get historyOrders() {
         return this.orders ?? []
     }

     public set addPoint(point : number | string) {
        this.newPoint = point
     }

    public static get COLUMNS() : Array<ColumnInterface> {
        return [
            {name : "Id" , value : "customId"},
            {name : "Nom" , value : "last_name"},
            {name : "Prénom" , value : "first_name"},
            {name : "Adresse Mail" , value : "email"},
            {name : "Nombre de point" , value : "nbPoint"},
            {name : "Date dernière commande" , value : "dateLastOrder"},
            {name : "Dépense Totale" , value : "totalExpend"},
        ]
    }

    public static get EXPORT_CSV() : Array<ColumnInterface> {
        return [
            {name : "Id" , value : "customId"},
            {name : "Nom" , value : "last_name"},
            {name : "Prénom" , value : "first_name"},
            {name : "Adresse Mail" , value : "email"},
            {name : "Téléphone" , value : "phone"},
            {name : "Adresse" , value : "address"},
            {name : "Code postal" , value : "zipCode"},
            {name : "Ville" , value : "city"},
            {name : "Pays" , value : "country"},
            {name : "Nombre de point" , value : "nbPoint"},
            {name : "Date dernière commande" , value : "dateLastOrder"},
            {name : "Dépense Totale" , value : "totalExpend"},
            {name : "Genre" , value : "gender"},
        ]
    }

    public static get FORM_ADD() : Array<FormInterface>{
        return [
            {
                wording : "Id du client",
                value : "customId",
                type : "readonly",
                position : "customer-grid-1"
            }, {
                wording : "Nom",
                value : "last_name",
                type : "readonly",
                position : "customer-grid-1"
            }, {
                wording : "Prénom",
                value : "first_name",
                type : "readonly",
                position : "customer-grid-1"
            },{
                wording : "Client depuis le :",
                value : "dateFirstOrder",
                type : "readonly",
                position : "customer-grid-1"
            }, {
                wording : "Pseudo",
                value : "username",
                type : "readonly",
                position : "customer-grid-2-2"
            }, {
                wording : "Adresse Mail",
                value : "email",
                type : "readonly",
                position : "customer-grid-2-2"
            }, {
                wording : "Téléphone",
                value : "phone",
                type : "readonly",
                position : "customer-grid-2-2"
            }, {
                wording : "Sexe",
                value : "manOrWoman",
                type : "readonly",
                position : "customer-grid-2-2"
            }, {
                wording : "Adresse",
                value : "addressCustomer",
                type : "readonly",
                position : "customer-grid-2-1"
            }, {
                wording : "Ville",
                value : "cityCustomer",
                type : "readonly",
                position : "customer-grid-2-1"
            }, {
                wording : "Code Postal",
                value : "zipCodeCustomer",
                type : "readonly",
                position : "customer-grid-2-1"
            }, {
                wording : "Pays",
                value : "country",
                type : "readonly",
                position : "customer-grid-2-1"
            }, {
                wording : "Nombre Total de commandes",
                value : "nbTotalOrders",
                type : "readonly",
                position : "customer-grid-3-1"
            }, {
                wording : "Nb de point",
                value : "points",
                type : "readonly",
                position : "customer-grid-5"
            }, {
                wording : "Ajouter des points",
                value : "addPoint",
                type : "number",
                position : "customer-grid-5"
            }, {
                wording : "Prix moyen des commandes",
                value : "averagePrice",
                type : "readonly",
                position : "customer-grid-3-1"
            }, {
                wording : "Dépense Totale",
                value : "totalExpend",
                type : "readonly",
                position : "customer-grid-3-1"
            }, {
                wording : "historique des commandes",
                value : "historyOrders",
                type : "custom",
                position : "customer-grid-4-1"
            }, {
                wording : "produits",
                value : "listProducts",
                type : "custom",
                position : "customer-grid-9-1"
            }
        ]
    }

    public get BODY() {
        return {
            addPoint : this.addPoint
        }
    }

    public static get FILTERS_FOR_CUSTOMERS(): Array<FilterInterface> {
        return [{
            wording: "Id",
            value: "customId",
            type: "text"
        }, {
            wording: "Nom",
            value: "last_name",
            type: "text"
        }, {
            wording: "Prénom",
            value: "first_name",
            type: "text"
        }, {
            wording: "Pseudo",
            value: "username",
            type: "text"
        }, {
            wording: "Email",
            value: "email",
            type: "text"
        }, {
            wording : "Nombre de point",
            value : "nbPoint",
            type : "number"
        }, {
            wording : "Dépense Totale",
            value : "total_expenditure",
            type : "number"
        },{
            wording : "Date dernière commande",
            value : "date_last_order",
            type : "date"
        },{
            wording : "Sexe",
            value : "manOrWoman",
            type : "select",
            options : [
                {value : "Homme", name : "Homme"},
                {value : "Femme", name : "Femme"},
            ]
        }]
    }

}

export default Customer
