import Category from '../objects/Category';
import Collection from '../objects/Collection';
import ServiceInterface from '../objects/interfaces/ServiceInterface';
import Order from '../objects/Order';
import Product from '../objects/Product';
import Customer from '../objects/Customer';
import User from '../objects/User';
import Invoice from '../objects/Invoice';
import Gift from '../objects/Gift';
import NfcTag from '../objects/NfcTag';
import Setting from '../objects/Setting';
import LaunchVisual from '../objects/LaunchVisual';

const REQUESTS : Array<ServiceInterface> = [{
    name : "ORDERS_NO_PROCESSED",
    url : "/api/orders",
    method : "GET",
    object : Order,
    params : {isProcessed : false}
}, {
    name : "ORDERS_PROCESSED",
    url : "/api/orders",
    method : "GET",
    object : Order,
    params : {isProcessed : true}
}, {
    name : "ORDERS",
    url : "/api/orders",
    method : "GET",
    object : Order
}, {
    name : "ORDERS",
    url : "/api/orders",
    method : "PUT",
    object : Order
},{
    name : "ORDERS",
    url : "/api/orders",
    method : "DELETE",
    object : Order
}, {
    name : "CATEGORIES",
    url : "/api/category",
    method : "GET",
    object : Category,
    reducerPath : "categories"
}, {
    name : "COLLECTIONS",
    url : "/api/collection",
    method : "GET",
    object : Collection,
    reducerPath : "collections"
}, {
    name : "PRODUCTS",
    url : "/api/products",
    method : "GET",
    object : Product,
}, {
    name : "CUSTOMERS",
    url : "/api/customers",
    method : "GET",
    object : Customer,
    reducerPath : "customers"
}, {
    name : "CUSTOMERS",
    url : "/api/customers",
    method : "PUT",
    object : Customer,
    reducerPath : "customers"
}, {
    name : "CUSTOMERS",
    url : "/api/customers",
    method : "DELETE",
}, {
    name : "COLLECTIONS",
    url : "/api/collection",
    method : "POST",
    object : Collection,
    reducerPath : "collections"
}, {
    name : "CATEGORIES",
    url : "/api/category",
    method : "POST",
    object : Category,
    reducerPath : "categories"
}, {
    name : "COLLECTIONS",
    url : "/api/collection",
    method : "PUT",
    object : Collection,
    reducerPath : "collections"
}, {
    name : "CATEGORIES",
    url : "/api/category",
    method : "PUT",
    object : Category,
    reducerPath : "categories"
}, {
    name : "COLLECTIONS",
    url : "/api/collection",
    method : "DELETE",
    reducerPath : "collections"
}, {
    name : "CATEGORIES",
    url : "/api/category",
    method : "DELETE",
    reducerPath : "categories"
}, {
    name : "PRODUCTS",
    url : "/api/product",
    method : "POST",
    object : Product,
}, {
    name : "PRODUCTS",
    url : "/api/product",
    method : "PUT",
    object : Product,
}, {
    name : "PRODUCTS",
    url : "/api/product",
    method : "DELETE", 
}, {
    name : "VALIDATE_ORDER",
    url : "/api/validate_order",
    method : "PUT",
    object : Order
}, {
    name : "CANCEL_ORDER",
    url : "/api/cancel_order",
    method : "PUT",
    object : Order
}, {
    name : "USERS",
    url : "/api/users",
    method : "GET",
    object : User,
    reducerPath : "users"
}, {
    name : "USERS",
    url : "/api/users",
    method : "POST",
    object : User,
    reducerPath : "users"
}, {
    name : "USERS",
    url : "/api/users",
    method : "PUT",
    object : User,
    reducerPath : "users"
}, {
    name : "USERS",
    url : "/api/users",
    method : "DELETE",
},{
    name : 'PHONE_LOGIN',
    url : "api/phoneLogin",
    method : "POST"
}, {
    name : "INVOICES",
    url : "/api/invoices",
    method : "GET",
    reducerPath : "invoices",
    object : Invoice
}, {
    name : "GIFTS",
    url : "api/gifts",
    method : "GET",
    reducerPath : "gifts",
    object : Gift
}, {
    name : "NFC_TAGS",
    url : "api/nfcTags",
    method : "GET",
    reducerPath : "nfcTags",
    object : NfcTag
}, {
    name : "NFC_TAGS_BY_CUSTOMERS",
    url : "api/customers/{id}/nfcTags",
    method : "GET",
}, {
    name : "SETTINGS",
    url : "api/settings",
    method : "GET",
    reducerPath : "settings",
    object : Setting
}, {
    name : "SETTINGS",
    url : "api/settings",
    method : "PUT"
}, {
    name : "LAUNCH_VISUAL",
    url : "api/current_launch_visual",
    method : "GET",
    object : LaunchVisual
},{
    name : "LAUNCH_VISUAL",
    url : "api/launch_visuals",
    method : "POST",
    object : LaunchVisual
}, {
    name : "ADD_POINTS_TO_CUSTOMERS",
    url : "api/add_points",
    method : "PUT"
}, {
    name : "CURRENT_ADMIN",
    url : "api/current_admin",
    method : "GET",
    reducerPath : "currentAdmin"
}, {
    name : "RESEND_MAIL",
    url : "api/resend_mail_gift",
    method : "PUT",
}]

export default REQUESTS
