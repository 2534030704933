import File from './File';
import LaunchVisualInterface from './interfaces/LaunchVisualInterface';

class LaunchVisual {
    id ? : number
    file ? : File

    constructor(launchVisual ? : LaunchVisualInterface) {
        this.id = launchVisual?.id
        this.file = new File(launchVisual?.file)
    }
}

export default LaunchVisual
