import ColumnInterface from './interfaces/ColumnInterface';
import OrderInterface from './interfaces/OrderInterface';
import Customer from './Customer';
import dateToString from '../utils/dateToString';
import ProductOrder from './ProductOrder';
import FormInterface from './interfaces/FormInterface';
import hundredth from '../utils/hundredth';
import NfcTag from './NfcTag';
import NfcTagInterface from './interfaces/NfcTagInterface';
import Invoice from './Invoice';
import FilterInterface from './interfaces/FilterInterface';


class Order {
    readonly id: number;
    readonly custom_id: string;
    customer?: Customer;
    creationDate: Date;
    products?: Array<ProductOrder>
    isProcessed: boolean
    isCancelled: boolean
    source: "WEB" | "APP"
    isCustomAddressDelivery?: boolean
    nfcTags ? : Array<NfcTag>
    invoice ? : Invoice

    constructor(order: OrderInterface) {
        this.id = order.id
        this.custom_id = order.custom_id
        this.customer = new Customer(order.customer)
        this.creationDate = order.creationDate
        let products: Array<ProductOrder> = []
        order.products?.forEach(product => {
            products.push(new ProductOrder(product))
        });
        this.products = products
        this.isCancelled = order.isCancelled
        this.isProcessed = order.isProcessed
        this.source = order.source
        this.isCustomAddressDelivery = order.isCustomAddressDelivery
        let nfcTags  = order?.nfcTags?.reduce((acc : Array<NfcTag>, curr : NfcTagInterface) => {
            acc.push(new NfcTag(curr))
            return acc
        }, []) ?? []
        this.nfcTags = nfcTags
        this.invoice = new Invoice(order?.invoice)
    }

    public get firstNameCustomer(): string | undefined {
        return this.customer?.firstName ?? "-"
    }

    public get lastNameCustomer(): string | undefined {
        return this.customer?.lastName ?? "-"
    }

    public get emailCustomer() : string {
        return this.customer?.email ?? "-"
    }

    public get phoneCustomer() : string {
        return this.customer?.phone ?? "-"
    }

    public get idCustomer(): string | undefined {
        return this.customer?.customId ?? "-"
    }

    public get nbPointCustomer(): number | undefined {
        return this.customer?.nbPoint ?? 0
    }

    public get addressCustomer(): string | undefined {
        return this.customer?.address ?? "-"
    }

    public get zipCodeCustomer(): string | undefined {
        return this.customer?.zipCode ?? "-"
    }

    public get cityCustomer(): string | undefined {
        return this.customer?.city ?? "-"
    }

    public get countryCustomer(): string | undefined {
        return this.customer?.country ?? "-"
    }

    public get sameAddress(): string {
        return this.isCustomAddressDelivery ? "Non" : "Oui"
    }

    public get dateOrder(): string {
        return dateToString(this.creationDate)
    }

    public get productsTable(): Array<ProductOrder> {
        return this.products ?? []
    }

    public get totalPriceInvoice()  {
        return this.invoice?.totalPriceString
    }

    public get totalPrice(): string {
        let totalPrice: number = 0
        this.products?.forEach(product => {
            totalPrice += hundredth((product.amount * (product.product?.price ?? 0)))
        })
        return `${hundredth(totalPrice)} €`
    }

    public get totalPoint(): string {
        let totalPoint: number = 0
        this.products?.forEach(product => {
            totalPoint += (product.amount * (product.product?.point ?? 0))
        })
        return `${totalPoint}`
    }

    public get statut(): string {
        return !this.isProcessed ? "à traiter" : this.isCancelled ? "annulée" : "traitée"
    }

    public static get FORM(): Array<FormInterface> {
        return [{
            wording: "Id de la commande",
            value: "custom_id",
            type: "readonly",
            position: "order-grid-1"
        }, {
            wording: "Id du Client",
            value: "idCustomer",
            type: "readonly",
            position: "order-grid-2"
        }, {
            wording: "Nom",
            value: "lastNameCustomer",
            type: "readonly",
            position: "order-grid-2"
        }, {
            wording: "Prénom",
            value: "firstNameCustomer",
            type: "readonly",
            position: "order-grid-2"
        }, {
            wording: "Points Actuels",
            value: "nbPointCustomer",
            type: "readonly",
            position: "order-grid-2"
        }, {
            wording: "Addresse Mail",
            value: "emailCustomer",
            type: "readonly",
            position: "order-grid-2"
        }, {
            wording: "Téléphone",
            value: "phoneCustomer",
            type: "readonly",
            position: "order-grid-2"
        }, {
            wording: "Adresse de Facturation",
            value: "addressCustomer",
            type: "readonly",
            position: "order-grid-3"
        }, {
            wording: "Ville de Facturation",
            value: "cityCustomer",
            type: "readonly",
            position: "order-grid-3"
        }, {
            wording: "Code Postal de Facturation",
            value: "zipCodeCustomer",
            type: "readonly",
            position: "order-grid-3"
        }, {
            wording: "Pays de Facturation",
            value: "countryCustomer",
            type: "readonly",
            position: "order-grid-3"
        }, {
            wording: "Même adresse pour la Livraison",
            value: "sameAddress",
            type: "readonly",
            position: "order-grid-4"
        }, {
            wording: "Adresse de Livraison",
            value: "addressCustomer",
            type: "readonly",
            position: "order-grid-4",
            config: {
                isVisible: {
                    name: "isCustomAddressDelivery",
                    equalTo: true
                }
            }
        }, {
            wording: "Ville de Livraison",
            value: "cityCustomer",
            type: "readonly",
            position: "order-grid-4",
            config: {
                isVisible: {
                    name: "isCustomAddressDelivery",
                    equalTo: true
                }
            }
        }, {
            wording: "Code Postal de Livraison",
            value: "zipCodeCustomer",
            type: "readonly",
            position: "order-grid-4",
            config: {
                isVisible: {
                    name: "isCustomAddressDelivery",
                    equalTo: true
                }
            }
        }, {
            wording: "Pays de Livraison",
            value: "countryCustomer",
            type: "readonly",
            position: "order-grid-4",
            config: {
                isVisible: {
                    name: "isCustomAddressDelivery",
                    equalTo: true
                }
            }
        }, {
            wording: "Récapitulatif des commandes",
            value: "productsTable",
            type: "custom",
            position: "order-grid-5",
        }, {
            wording: "Prix Total",
            value: "totalPrice",
            type: "readonly",
            position: "order-grid-5",
        }, {
            wording: "Points Total",
            value: "totalPoint",
            type: "readonly",
            position: "order-grid-5",
        }
        ]
    }

    public static get COLUMNS(): Array<ColumnInterface> {
        return [
            { name: "Id de la commande", value: "custom_id" },
            { name: "Nom", value: "lastNameCustomer" },
            { name: "Prénom", value: "firstNameCustomer" },
            { name: "Email", value: "emailCustomer" },
            { name: "Date", value: "dateOrder" },
            { name: "Id du client", value: "idCustomer" },
            { name: "Source", value: "source" },
            { name: "Statut", value: "statut" }
        ]
    }

    public static get FILTERS_FOR_ORDERS(): Array<FilterInterface> {
        return [{
            wording: "Id",
            value: "custom_id",
            type: "text"
        }, {
            wording: "Nom",
            value: "lastNameCustomer",
            type: "text"
        }, {
            wording: "Prénom",
            value: "firstNameCustomer",
            type: "text"
        }, {
            wording: "Adresse Mail",
            value: "emailCustomer",
            type: "text"
        },{
            wording : "Id du client",
            value : "idCustomer",
            type :"text"
        }, {
            wording : "Source",
            value : "source",
            type : "select",
            options : [
                {value : "APP", name : "APP"},
                {value : "WEB", name : "WEB"},
            ]
        }]
    }


    public static get EXPORT_CSV() : Array<ColumnInterface> {
        return [
            { name: "Id de la commande", value: "custom_id" },
            { name: "Nom", value: "lastNameCustomer" },
            { name: "Prénom", value: "firstNameCustomer" },
            { name: "Email", value: "emailCustomer" },
            { name: "Téléphone", value: "phoneCustomer" },
            { name: "Adresse", value: "addressCustomer" },
            { name: "Code postal", value: "zipCodeCustomer" },
            { name: "Ville", value: "cityCustomer" },
            { name: "Pays", value: "countryCustomer" },
            { name: "Date", value: "dateOrder" },
            { name: "Id du client", value: "idCustomer" },
            { name: "Source", value: "source" },
            { name: "Statut", value: "statut" },
            { name: "Prix Total", value: "totalPriceInvoice" },
        ]
    }
}

export default Order
