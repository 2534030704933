import * as React from 'react';
import { useState, useEffect } from 'react';
import Field from '../../../components/Field/Field';
import Page from '../../../components/Page/Page';
import useAPI from '../../../hooks/useAPI';
import Setting from '../../../objects/Setting';
import "./Settings.css"
import { toast } from 'react-toastify';
import useData from '../../../hooks/useData';
import SettingInterface from '../../../objects/interfaces/SettingInterface';
interface SettingsProps {
    history: any
}
 
const Settings: React.FunctionComponent<SettingsProps> = ({history}) => {

    const [settings, setSettings] = useState<Array<Setting>>([])
    const [settingsData, setSettingsData] = useData("settings")

    const [getSettings, responseGetSettings] = useAPI("SETTINGS", "GET")
    useEffect(() => {
        const {onSuccess, data} = responseGetSettings
        if(onSuccess) {
            setSettings(data)
        }
    }, [responseGetSettings]);


    const [putSettings, responsePutSettings] = useAPI("SETTINGS", "PUT")
    useEffect(() => {
        const {onSuccess, data, onFailure} = responsePutSettings
        if(onSuccess) {
            toast.success("Modifier avec succès")
            data.reduce((acc : Array<Setting>, curr : SettingInterface) => {
                acc.push(new Setting(curr))
                return acc
            }, [])
            setSettingsData(data)
        }
        if(onFailure) {
            toast.error("Une erreur est survenue lors de la modification")
        }
    }, []);

    useEffect(() => {
        getSettings()
    }, []);


    const onChange = (name : string, value : string) => {
        let newSettings = settings.reduce((acc : Array<Setting>, curr : Setting) => {
            if(name === curr.name) {
                curr.value = parseFloat(value)
            }
            acc.push(curr)
            return acc
        }, [])
        setSettings(newSettings)
    }

    console.log(settings)
    return ( 
        <Page history={history} name="Paramètres">
            <div className="settings-container">
                <h3>GESTION DES FRAIS DE LIVRAISON</h3>
                <div className="delivering-setting">
                <Field type="number" value={settings.find(s => s.name === "CLASSIC_DELIVERY_PRICE")?.value ?? "0"} onChange={(value) => onChange("CLASSIC_DELIVERY_PRICE", value )} wording="Tarif Livraison Classique : (en €)"/>
                <Field type="number" value={settings.find(s => s.name === "EXPRESS_DELIVERY_PRICE")?.value ?? "0"} onChange={(value) => onChange("EXPRESS_DELIVERY_PRICE", value )} wording="Tarif Livraison Express : (en €)"/>
                </div>

                <div onClick={() => putSettings({body : settings})} className="btn-success">Valider</div>
            </div>
        </Page>
     );
}
 
export default Settings;
