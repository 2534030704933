import * as React from 'react';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../../components/Page/Page';

interface NotificationsProps extends RouteComponentProps<any>{
    
}
 
const Notifications: React.FunctionComponent<NotificationsProps> = ({history}) => {
    return ( 
        <Page history={history} name="Notification">
            <div className="notifications-container">
                Notif
            </div>
        </Page>
     );
}
 
export default Notifications;
